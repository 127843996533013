export const environment = {
  production: true,
  apiUrl: 'https://api-new.autotaxatiepartners.nl',
  application: 'atp-admin',
  password: 'FDWAx;n|nBT5^fwxjY^C',
  // First item will be the default, one entry will hide the field.
  level: ['person'],
  websocketURL: 'wss://m27ul7t5cb.execute-api.eu-west-1.amazonaws.com/prod/',

  openAiSecretKey: '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',
  openAiOrganizationId: 'org-d5mWXCTUaDOhuxNHVxRdmBwl'
};